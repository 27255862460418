import styled from "styled-components";
import ResponsiveImage from "../contentComponents/view/ResponsiveImage";
import { breakPoints, colors, measurments, shadows } from "../../utils/config";
import { Link } from "react-router-dom";
import Tag from "../../sharedUI/Tag";
import Button from "../../sharedUI/Button";
import { useParams } from "react-router-dom";
import { useQuery } from "../../utils/useQuery";

const StyledGuideEntryCard = styled.div`
  margin-bottom: 1rem;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  overflow: hidden;
  display: grid;
  grid-template-columns: 140px 1fr;
  .entry-image {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .entry-text-container {
    padding: 1.5rem 1.5rem 1.2rem 1.5rem;
  }
  &.narrow {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    .entry-image {
      height: 12rem;
    }
  }
  ${breakPoints.mobile} {
    .entry-image {
    }
    display: block;
    .entry-text-container {
      padding: 1rem;
    }
  }
`;

const GuideEntryCard = ({ entry, narrow }) => {
  const { tag } = useParams();
  const queryCategory = useQuery().get("kategori");

  const linkCategory = encodeURI(tag || entry.tagModels[0].text);
  const query = queryCategory
    ? `?kategori=${encodeURIComponent(queryCategory)}`
    : "";

  const sortedTags = entry.tagModels.sort((a, b) =>
    a.text.localeCompare(b.text)
  );

  return (
    <Link to={`/tranarguide/${linkCategory}/${entry._id}${query}`}>
      <StyledGuideEntryCard className={narrow ? "narrow" : ""}>
        <div className="entry-image">
          <ResponsiveImage file={entry.image} />
        </div>
        <div className="entry-text-container">
          <p className="h3 m0">{entry.title}</p>
          {!narrow && (
            <div className="flex gap05 mtop05 wrap">
              {sortedTags.map((tag) => (
                <Tag
                  size="small"
                  key={`${tag._id}_${entry._id}`}
                  tagName={tag.text}
                  onWhite
                  bgColor={colors.neutral[30]}
                  hoverBg={colors.neutral[30]}
                />
              ))}
            </div>
          )}
          <div className="mtop1 flex jcfe">
            <Button btnType="neutral">Till guide</Button>
          </div>
        </div>
      </StyledGuideEntryCard>
    </Link>
  );
};

export default GuideEntryCard;
