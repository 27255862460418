import styled from "styled-components";
import { useCache } from "../../caching/caches";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import EventPlanCard from "../../components/cards/EventPlanCard";
import GuideEntryCard from "../../components/cards/GuideEntryCard";
import CourseCard, {
  CourseCardLoader,
} from "../../components/cards/CourseCard";
import { breakPoints } from "../../utils/config";
import WelcomeMessage from "../../components/ui/WelcomeMessage";
import IconButton from "../../sharedUI/IconButton";

const StyledHome = styled.div`
  padding-bottom: 20rem;
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .see-more-link {
    button {
      padding: 0;
    }
  }
  .guides-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 3rem;
  }
  ${breakPoints.tablet} {
    .guides-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  ${breakPoints.mobile} {
    .grid-container {
      grid-template-columns: 1fr;
    }
    .guides-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Home = () => {
  const { state: _guides, loading: guidesLoading } = useCache("guide");
  const { state: _events, loading: eventsLoading } = useCache("events");
  const { state: _courses, loading: coursesLoading } = useCache("courses");
  const { state: progresses, loading: progressLoading } =
    useCache("progresses");

  const guides = useMemo(() => {
    return _guides.filter((e) => e.featured);
  }, [_guides]);

  const events = useMemo(() => {
    return _events.filter((e) => e.featured);
  }, [_events]);

  const courses = useMemo(() => {
    return _courses
      .filter((course) => {
        const progress = progresses.find(
          (progress) => progress.course === course._id
        );
        return progress && progress.completionPercent !== 100;
      })
      .map((course) => ({
        ...course,
        progress: progresses.find((progress) => progress.course === course._id),
      }));
  }, [_courses, progresses]);

  return (
    <StyledHome className="content ptop2">
      <WelcomeMessage />
      {courses.length ? (
        <>
          <div className="flex gap1 ac mtop2 mbottom1">
            <h3 className="h3 m0 ">Påbörjade utbildningar</h3>
            <Link to="/kurser">
              <IconButton trans iconRight="chevron-right">
                Se alla
              </IconButton>
            </Link>
          </div>
          <div>
            {coursesLoading || progressLoading ? (
              <CourseCardLoader />
            ) : courses.length ? (
              courses.map((course) => (
                <CourseCard course={course} key={course._id} />
              ))
            ) : (
              <p>Inga påbörjade utbildningar</p>
            )}
          </div>
        </>
      ) : null}
      <div className="flex gap1 ac mtop2 mbottom1">
        <h3 className="h3 m0 ">Aktuella evenemang</h3>
        <Link to="/evenemang">
          <IconButton trans iconRight="chevron-right">
            Se alla
          </IconButton>
        </Link>
      </div>
      <div className="grid-container">
        {eventsLoading ? (
          <CourseCardLoader />
        ) : events.length ? (
          events.map((eventEntry) => (
            <EventPlanCard entry={eventEntry} key={eventEntry._id} />
          ))
        ) : (
          <p>Inga aktuella evenemang</p>
        )}
      </div>
      <div className="flex gap1 ac mtop2 mbottom1">
        <h3 className="h3 m0">Utvalda guider</h3>
        <Link to="/tranarguide">
          <IconButton trans iconRight="chevron-right">
            Se alla
          </IconButton>
        </Link>
      </div>
      <div className="guides-grid">
        {guidesLoading ? (
          <CourseCardLoader />
        ) : guides.length ? (
          guides.map((guide) => (
            <GuideEntryCard narrow entry={guide} key={guide._id} />
          ))
        ) : (
          <p>Inga utvalda guider</p>
        )}
      </div>
    </StyledHome>
  );
};

export default Home;
