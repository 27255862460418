import styled from "styled-components";
import { useMemo } from "react";
import Skeleton from "../../components/ui/Skeleton";
import GuideEntryCard from "../../components/cards/GuideEntryCard";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import { useCache } from "../../caching/caches";
import { breakPoints } from "../../utils/config";
import GuideCategoryCard from "../../components/cards/GuideCategoryCard";
import { useQuery } from "../../utils/useQuery";
import { Link } from "react-router-dom";

const StyledGuides = styled.div`
  padding-bottom: 20rem;
  .featured {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 3rem;
  }
  .category-header {
    display: flex;
    align-items: center;
    h4,
    h3 {
      margin: 0;
      margin-bottom: 1rem;
    }
    a {
      h3 {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
  .categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  ${breakPoints.tablet} {
    .featured {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  ${breakPoints.mobile} {
    .featured {
      grid-template-columns: repeat(1, 1fr);
    }
    .categories {
      grid-template-columns: 1fr;
    }
  }
`;

const Guides = () => {
  const { state: entries, loading: entriesLoading } = useCache("guides");
  const { state: categories, loading: categoriesLoading } =
    useCache("guideCategories");
  const queryCategory = useQuery().get("kategori");

  const featured = useMemo(() => {
    return entries.filter((entry) => entry.featured);
  }, [entries]);

  const subCategories = useMemo(() => {
    const category = categories.find((cat) => cat.text === queryCategory);
    if (!category) return [];
    return category.tags;
  }, [queryCategory, categories]);

  const loading = useMemo(() => {
    return entriesLoading || categoriesLoading;
  }, [entriesLoading, categoriesLoading]);

  return (
    <StyledGuides className="content">
      <h1 className="mtop2 m0">Tränarguiden</h1>
      <p className="mbottom2">
        Den här guiden är gjord för dig som är tränare i Brommagymnasterna. Vi
        vill göra det så lätt som möjligt för dig som tränare att ge dina
        gymnaster rolig och varierad träning som är anpassad efter deras nivå.
        Genom en blandning av instruktion, inspiration och vägledning är målet
        att lyfta fram Brommagymnasternas nycklar till gymnastik.
      </p>
      {!!featured.length && (
        <>
          <h3>Utvalda guider</h3>
          <div className="featured">
            {featured.map((entry) => (
              <GuideEntryCard narrow key={`feat_${entry._id}`} entry={entry} />
            ))}
          </div>
        </>
      )}
      {queryCategory && subCategories.length ? (
        <>
          <div className="category-header">
            <Link to="/tranarguide">
              <h3>Kategorier</h3>
            </Link>
            <h4>&nbsp;/&nbsp;{queryCategory}</h4>
          </div>
          <div className="categories">
            {subCategories.map((tag) => (
              <GuideCategoryCard
                key={tag._id}
                category={tag}
                parent={queryCategory}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <h3>Kategorier</h3>
          <div className="categories">
            {loading ? (
              <Skeleton
                identifier="guides"
                quantity={5}
                Component={CourseCardLoader}
              />
            ) : categories.length ? (
              categories.map((tag) => (
                <GuideCategoryCard key={tag._id} category={tag} top />
              ))
            ) : (
              <p>Inga övningar hittades</p>
            )}
          </div>
        </>
      )}
    </StyledGuides>
  );
};

export default Guides;
