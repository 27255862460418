import styled from "styled-components";
import ResponsiveImage from "../contentComponents/view/ResponsiveImage";
import { colors, measurments, shadows } from "../../utils/config";
import { Link } from "react-router-dom";
import Button from "../../sharedUI/Button";
import { parseDate } from "../../utils/parseDate";

const StyledEventPlanCard = styled.div`
  margin-bottom: 1rem;
  height: 100%;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  overflow: hidden;
  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
  display: grid;
  grid-template-rows: auto 1fr;
  .text-container {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    .text-inner {
      flex-grow: 1;
    }
    .button-container {
      margin-top: auto;
      margin-left: auto;
    }
  }
  .event-date {
    font-size: 0.75rem;
    margin: 0;
    padding: 2px 0.5rem;
    border-radius: 50px;
    background: ${colors.neutral[20]};
  }
`;

const EventPlanCard = ({ entry }) => {
  const dateString = () => {
    let str = parseDate(entry.eventDate);
    if (entry.endDate) str += ` — ${parseDate(entry.endDate)}`;
    return str;
  };

  return (
    <Link to={`/evenemang/${entry._id}`}>
      <StyledEventPlanCard>
        <ResponsiveImage file={entry.image} />
        <div className="text-container">
          {!!entry.eventDate && <p className="event-date">{dateString()}</p>}
          <div className="text-inner">
            <p className="h3 m0">{entry.title}</p>
            {!!entry.ingress && <p className="m0">{entry.ingress}</p>}
          </div>
          <div className="button-container">
            <Button btnType="neutral">Till evenemanget</Button>
          </div>
        </div>
      </StyledEventPlanCard>
    </Link>
  );
};

export default EventPlanCard;
